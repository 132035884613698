/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {CopyDemo} from "./";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code",
    pre: "pre",
    h2: "h2"
  }, _provideComponents(), props.components), {Aside, YouTube} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  if (!YouTube) _missingMdxReference("YouTube", true);
  return React.createElement(React.Fragment, null, React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: The ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API"
  }, "Clipboard API"), " is quite good."), React.createElement(_components.p, null, "The one line: ", React.createElement(_components.code, null, "navigator.clipboard.writeText(\"potatoes\");"))), "\n", React.createElement(_components.p, null, "Copying something to the clipboard can be done in one line now.\nThe ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API"
  }, "Clipboard API"), " is what powers this.\nIt has a bunch of asynchronous methods, meaning they return promises.\nThe promise resolves? Neat, do things afterwards."), "\n", React.createElement(_components.p, null, "This example React component uses the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText"
  }, React.createElement(_components.code, null, "writeText")), " method to copy a string to the clipboard."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    title: "CopyDemo.js",
    hl: "7"
  }, "import { useState } from \"react\";\n\nconst CopyDemo = () => {\n  const text = \"Boil em, mash em, stick em in a stew.\";\n  const [copied, setCopied] = useState(false);\n  const copy = async () => {\n    await navigator.clipboard.writeText(text);\n    setCopied(true);\n    setTimeout(() => setCopied(false), 1000);\n  };\n  return (\n    <div>\n      <p>{text}</p>\n      <button onClick={copy}>{copied ? \"Copied\" : \"Copy\"}</button>\n    </div>\n  );\n};\n")), "\n", React.createElement(_components.p, null, "After adding some CSS, that looks like this:"), "\n", React.createElement(CopyDemo), "\n", React.createElement(_components.p, null, "If you prefer vanilla JS, check out ", React.createElement(_components.a, {
    href: "https://codepen.io/NMeuleman/pen/bGRGpdj"
  }, "my copy button codepen"), "."), "\n", React.createElement(_components.p, null, "Click the copy button and BAM, Samwise Gamgee’s words of wisdom are now in the clipboard."), "\n", React.createElement(YouTube, {
    youTubeId: "ihMMw0rnKz4"
  }), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "While writing to the clipboard can always be done in the active tab using the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API"
  }, "Clipboard API"), ", reading the clipboard needs to be permitted.")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "As with many modern APIs, ", React.createElement(_components.code, null, "navigator.clipboard"), " is only available on HTTPS pages.")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Optional chaining (", React.createElement(_components.code, null, "?."), " syntax) can not be used on things like ", React.createElement(_components.code, null, "window"), " or ", React.createElement(_components.code, null, "navigator"), ".\nI would have liked to use it, to prevent errors when rendering on the server without a ", React.createElement(_components.code, null, "window"), ", but, alas.")), "\n", React.createElement(_components.h2, {
    id: "browser-support"
  }, "Browser support"), "\n", React.createElement(_components.p, null, "This is available in ", React.createElement(_components.a, {
    href: "https://caniuse.com/mdn-api_clipboard_writetext"
  }, "all major browsers browsers"), ", except Interner Explorer."), "\n", React.createElement(_components.p, null, "With Microsoft ", React.createElement(_components.a, {
    href: "https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666"
  }, "ending Internet Explorer support"), " for some of their own major products.\nAnd planning to deprecate it further in 2021, I’m not worried about Internet Explorer lacking support for the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Clipboard"
  }, "clipboard API"), "."), "\n", React.createElement(Aside, {
    variant: "success"
  }, React.createElement(_components.p, null, "Update: It’s official.\n", React.createElement(_components.a, {
    href: "https://docs.microsoft.com/en-us/lifecycle/announcements/internet-explorer-11-end-of-support"
  }, "IE support has ended on June 15, 2022"))), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API"
  }, "Clipboard API"), " is meant to replace the old way of accessing the clipboard via ", React.createElement(_components.code, null, "document.execCommand()"), ".\n", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand"
  }, React.createElement(_components.code, null, "execCommand()"), " is deprecated"), ", only to be used for compatibility purposes."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
